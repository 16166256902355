

window.fixHashNavChrome = function fixHashNavChrome() {
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (window.location.hash && isChrome) {
        setTimeout(function () {
            var hash = window.location.hash;
            window.location.hash = "";
            window.location.hash = hash;
        }, 300);
    }
}

window.navWithinPage = function navWithinPage() {
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 300);
            window.location.hash=this.hash;
            return false;
          }
        }
    });

    $('#content > section').waypoint(function() {
        //$(".container ul li").children().removeClass("active");
        //$("#sec-2").addClass("active");
        //history.replaceState({}, document.title, window.location.pathname + window.location.search + "#" + this.element.id);
        history.replaceState(null, null, "#" + this.element.id);
    }, { offset: 0 });


/*$("a").click(function(event) {
  $("html,body").scrollTop($(this.hash)[0].offsetTop)
  event.preventDefault();
});*/

}


window.MD5 = function MD5(r) {
    var o, e, n, f = [ -680876936, -389564586, 606105819, -1044525330, -176418897, 1200080426, -1473231341, -45705983, 1770035416, -1958414417, -42063, -1990404162, 1804603682, -40341101, -1502002290, 1236535329, -165796510, -1069501632, 643717713, -373897302, -701558691, 38016083, -660478335, -405537848, 568446438, -1019803690, -187363961, 1163531501, -1444681467, -51403784, 1735328473, -1926607734, -378558, -2022574463, 1839030562, -35309556, -1530992060, 1272893353, -155497632, -1094730640, 681279174, -358537222, -722521979, 76029189, -640364487, -421815835, 530742520, -995338651, -198630844, 1126891415, -1416354905, -57434055, 1700485571, -1894986606, -1051523, -2054922799, 1873313359, -30611744, -1560198380, 1309151649, -145523070, -1120210379, 718787259, -343485551 ], t = [ o = 1732584193, e = 4023233417, ~o, ~e ], c = [], a = unescape(encodeURI(r)) + "\u0080", d = a.length;
    for (r = --d / 4 + 2 | 15, c[--r] = 8 * d; ~d; ) c[d >> 2] |= a.charCodeAt(d) << 8 * d--;
    for (i = a = 0; i < r; i += 16) {
        for (d = t; 64 > a; d = [ n = d[3], o + ((n = d[0] + [ o & e | ~o & n, n & o | ~n & e, o ^ e ^ n, e ^ (o | ~n) ][d = a >> 4] + f[a] + ~~c[i | 15 & [ a, 5 * a + 1, 3 * a + 5, 7 * a ][d]]) << (d = [ 7, 12, 17, 22, 5, 9, 14, 20, 4, 11, 16, 23, 6, 10, 15, 21 ][4 * d + a++ % 4]) | n >>> -d), o, e ]) o = 0 | d[1], 
        e = d[2];
        for (a = 4; a; ) t[--a] += d[a];
    }
    for (r = ""; 32 > a; ) r += (t[a >> 3] >> 4 * (1 ^ a++) & 15).toString(16);
    return r;
}

window.sha256 = async function sha256(message) {
    const msgUint8 = new TextEncoder().encode(message);                             // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);             // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer));                       // convert buffer to byte array
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');   // convert bytes to hex string
    return hashHex;
}

String.prototype.hexToBase64 = function() {
    return btoa(this.match(/\w{2}/g).map(function(a) {
        return String.fromCharCode(parseInt(a, 16));
    }).join(""));
};


/*
window.updateLangContent = function updateLangContent(langData) {
    document.querySelectorAll('[data-i18n]').forEach(element => {
        const key = element.getAttribute('data-i18n');
        if (typeof langData[key] === 'string') {
            element.textContent = langData[key];
        }
    });
}
*/
// Define language resources
const lang_resources = {
    lt: {
        translation: {
            "form-label-email": "El. paštas:",
            "form-label-phone": "Tel. Nr.:",
            "form-label-ticket": "Bilietas:",
            "form-tickettype-full": "Pilnas (121€, vėliau brangs)",
            "form-tickettype-discount": "Lengvatinis (70€)",
            "form-tickettype-camper": "Kemperis (60€)",
            "form-tickettype-belltent": "Bell tent nuoma (€175)",
            "form-input-namesurname": "Vardas Pavardė",
            "form-button-count": "Skaičiuoti"
            // Add more key-value pairs for Lithuanian translations
        }
    },
    en: {
        translation: {
            "form-label-email": "E-mail:",
            "form-label-phone": "Phone No.:",
            "form-label-ticket": "Ticket:",
            "form-tickettype-full": "Full (€121, will increase later)",
            "form-tickettype-discount": "Discounted (€70)",
            "form-tickettype-camper": "Camper (€60)",
            "form-tickettype-belltent": "Bell tent rent (€175)",
            "form-input-namesurname": "Name Surname",
            "form-button-count": "Calculate"
            // Add more key-value pairs for English translations
        }
    }
};

// Initialize i18next instance with language resources
i18next.init({
    lng: document.documentElement.lang, // dynamically set language based on HTML lang attribute
    resources: lang_resources
});

// Initialize jqueryI18next with the configured i18next instance
jqueryI18next.init(i18next, $, {
    tName: 't', // --> appends $.t = i18next.t
    i18nName: 'i18n', // --> appends $.i18n = i18next
    handleName: 'localize', // --> appends $(selector).localize(opts);
    selectorAttr: 'data-i18n', // selector for translating elements
    targetAttr: 'i18n-target', // data-() attribute to grab target element to translate (if different than itself)
    optionsAttr: 'i18n-options', // data-() attribute that contains options, will load/set if useOptionsAttr = true
    useOptionsAttr: false, // see optionsAttr
    parseDefaultValueFromContent: true // parses default values from content ele.val or ele.text
});

$(document).ready(function () {
/*    var lang = document.documentElement.lang;
    if (lang.length === 2 && window.langData[lang]) {
        updateLangContent(window.langData[lang]);
    }
*/  

    //createSoftConsole();

    $('[data-i18n]').localize();

    fixHashNavChrome();
    navWithinPage();
    setupScrollEvents();
    setTopShadowHeightOnInteraction();
    setupAccordion();

    console.log(`Window dimensions: width=${window.innerWidth}, height=${window.innerHeight}`);
    console.log('ready, ENV=' + ENV);
});


function createSoftConsole() {
    if (ENV === 'prod') {
        return;
    }
    // Create a console window
    window.consoleWindow = document.createElement('div');
    consoleWindow.style.position = 'fixed';
    consoleWindow.style.bottom = '0';
    consoleWindow.style.right = '0';
    consoleWindow.style.width = '300px';
    consoleWindow.style.height = '200px';
    consoleWindow.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    consoleWindow.style.color = 'white';
    consoleWindow.style.overflowY = 'scroll';
    consoleWindow.style.fontSize = '12px';
    consoleWindow.style.padding = '10px';
    consoleWindow.style.zIndex = '10000';
    consoleWindow.style.borderTopLeftRadius = '10px';
    consoleWindow.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.5)';
    document.body.appendChild(consoleWindow);

    // Override console.log to display messages in the console window
    window.originalConsoleLog = console.log;
    console.log = function(...args) {
        const message = args.join(' ');
        const messageElement = document.createElement('div');
        messageElement.textContent = message;
        consoleWindow.appendChild(messageElement);
        consoleWindow.scrollTop = consoleWindow.scrollHeight; // Auto-scroll to the bottom
        originalConsoleLog.apply(console, args);
    };
}


// top-shadow-height
/*window.addEventListener('load', () => {
    setTopShadowHeightOnInteraction();
});
window.addEventListener('resize', () => {
    setTopShadowHeightOnInteraction();
});*/

window.setTopShadowHeightOnInteraction = function setTopShadowHeightOnInteraction() {
    updateTopShadowHeight();
    window.addEventListener('resize', () => {
        updateTopShadowHeight();
    });

/*    const topShadow = document.querySelector('.top-shadow');
    //const nav = topShadow.querySelector('nav[lang='+document.documentElement.lang+']');

    //['mouseover', 'focusin', 'mouseenter', 'touchstart', 'visibilitychange'].forEach(event => {
    ['focusin', 'mouseenter', 'touchstart'].forEach(event => {        
        console.log('topShadow.addEventListener:'+event);
        topShadow.addEventListener(event, (e) => {
            console.log('hear: '+e);
            updateTopShadowHeight();
        });
    });
    /*['visibilitychange'].forEach(event => {
        console.log('nav.addEventListener:'+event);
        nav.addEventListener(event, (e) => {
            console.log('hear: '+e);
            updateTopShadowHeight(nav, topShadow);
        });
    });   */    
}

window.updateTopShadowHeight = function updateTopShadowHeight() {
    const topShadow = document.querySelector('.top-shadow');
    const nav = topShadow.querySelector('nav[lang='+document.documentElement.lang+']');    
    const originalDisplay = nav.style.display; // Store the original display style
    nav.style.display = 'block'; // Set nav visibility to block to get accurate height
    const navHeight = nav.offsetHeight;
    nav.style.display = originalDisplay; // Reset the display style to its original
    if (navHeight > 0) {
        
        const topShadowHeight = navHeight + 280 + 'px';
        //topShadow.style.height = topShadowHeight;
        if (updateTopShadowHeight.prevNavHeight !== navHeight || updateTopShadowHeight.prevTopShadowHeight !== topShadowHeight) {
            console.log(`navHeight=${navHeight}, topShadowHeight=${topShadowHeight}`);
            updateTopShadowHeight.prevNavHeight = navHeight;
            updateTopShadowHeight.prevTopShadowHeight = topShadowHeight;
        }
        document.querySelector(':root').style.setProperty('--top-shadow-height', topShadowHeight);
    }
}



//window.addEventListener('load', () => {
window.setupScrollEvents = function setupScrollEvents() {

    let ticking = false;
    let lastKnownScrollPosition = 0;
    let lastDownScrollPosition = 0;
    let hasScrolled = false;
    let lastScrollTop = 0;
 
    //document.querySelector('.parralax').addEventListener('scroll', (event) => {
    document.addEventListener('scroll', (event) => {        
        var winHeight = window.innerHeight;
        //var scrollTop = document.querySelector('.parralax').scrollTop;
        var scrollTop = window.scrollY;
        var scrollDir = Math.sign(scrollTop - lastKnownScrollPosition);
        if (!ticking) {
            if ( scrollDir > -1 ) {
                lastDownScrollPosition = scrollTop;
            }
            lastKnownScrollPosition = scrollTop;
            window.requestAnimationFrame(() => {
                document.documentElement.style.setProperty('--scrollPosition', lastKnownScrollPosition+'px');
                //document.documentElement.style.setProperty('--scrollPositionText', JSON.stringify(lastKnownScrollPosition + 'px'));
                document.documentElement.style.setProperty('--scrollPosTop', ( (lastKnownScrollPosition >= winHeight-110)&&(lastDownScrollPosition-lastKnownScrollPosition<220) ? -110 : 0) +'px');
                //console.log(`Last known pos: ${lastKnownScrollPosition}px`);
                ticking = false;
            });
        ticking = true;
        }

        const navParent = document.querySelector('header.top');
        //const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        
        //console.log(`Curr: ${scrollTop} | Prev: ${lastScrollTop} | Last known: ${lastKnownScrollPosition}`);
    
        if (scrollTop <= 0 && lastScrollTop > 0) {
            //navParent.classList.add('active');
            navParent.focus();
            console.log('nav:focus');
            updateTopShadowHeight();         
        } else if (lastScrollTop <= 0 && scrollTop > 0) {
            //navParent.classList.remove('active');
            navParent.blur();
            console.log('nav:blur');
        }
        
        lastScrollTop = scrollTop; // Track the last scroll position
    });

}

window.setupAccordion = function setupAccordion() {
    document.querySelectorAll('.accordion-title').forEach(label => {
        label.addEventListener('click', function() {
            //console.log('accordion label clicked');
            toggle = this.previousElementSibling;
            //console.log('toggle checked before? '+toggle.checked);
            if (toggle.checked) {
                //console.log('toggle checked after1? '+toggle.checked);
            } else {
                document.querySelectorAll('.accordion-toggle').forEach(input => {
                    if (input !== toggle && input.checked == true) {
                        console.log('unchecking: '+toggle.id);
                        input.checked = false;
                    }
                });
                //console.log('toggle checked after2? '+toggle.checked);
                setTimeout(() => {
                    window.scrollTo({
                        top: label.getBoundingClientRect().top + window.scrollY - 200,
                        behavior: 'smooth'
                    });
                }, 100); // Adjust the delay as needed
            }
            //document.querySelectorAll('.accordion-toggle').forEach(input => console.log(input.id+' = '+input.checked))
            //console.log('accordion scrolled');
            
        });
    });  
}